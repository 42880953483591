<template>
  <div>
    <h3 class="primary pa-1 mt-4 white--text">BODEN (NUTZUNG UND RISIKEN)</h3>
    <div>
      <!--
      GRN-SOI	-	BAUGRUNDBESCHAFFENHEIT
GRN-TRM	-	ERDWÄRMESONDEN
GRN-GWU	-	GRUNDWASSERNUTZUNG
GRN-RAD	-	RADON
GRN-CON	-	STANDORTBELASTUNG (KBS)
		
		
GRN-INF	-	VERSICKERUNGSZONEN
			
-->
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Name</th>
              <th class="text-left">Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Baugrund (Annahme)</td>
              <td>{{ plotItem.GRN_SOI }}</td>
            </tr>
            <tr>
              <td><b>Nutzung Erdwärmesonden</b></td>
              <td><b>{{ plotItem.GRN_TRM }}</b></td>
            </tr>
            <tr>
              <td><b>Nutzung Grundwasser</b></td>
              <td><b>{{ plotItem.GRN_GWU }}</b></td>
            </tr>
            <tr>
              <td><b>Radongehalt</b></td>
              <td><b>{{ plotItem.GRN_RAD }} <span v-if="plotItem.GRN_RAD > 10" style="color: red; font-size: 20px">&#9888;</span></b></td>
            </tr>
            <tr>
              <td>Standortbelastung (KBS)</td>
              <td>{{ plotItem.GRN_CON }}</td>
            </tr>
            <tr>
              <td>Versickerungszonen</td>
              <td>{{ plotItem.GRN_INF }}</td>
            </tr>

          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator"

@Component({
  components: {},
})
export default class ViewPlotDetails300 extends Vue {
  @Prop()
  plotItem
}
</script>

