<template>
  <div>
    <h3 class="primary pa-1 mt-4 white--text">HEIMATSCHUTZ</h3>
    <div>
      <!--
      PRO-BUN	-	BAUINVENTAR GEBÄUDE NATIONAL	
PRO-BUC	-	BAUINVENTAR  GEBÄUDE KANTONAL	
PRO-OOB	-	BAUINVENTAR ANDERE OBJEKTE	
PRO-BOO	-	GESCHÜTZTE BOTANISCHE OBJEKTE (PUNKTE / FLÄCHEN)	
PRO-ARS	-	ARCHÄOLOGISCHES SCHUTZGEBIET	
PRO-ARP	-	ARCHÄOLOGISCHE FUNDSTELLE	

-->
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Name</th>
              <th class="text-left">Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>geschütztes Gebäude (Nat.)</td>
              <td>{{ plotItem.PRO_BUN }}</td>
            </tr>
            <tr>
              <td>geschütztes Gebäude (Kant.)</td>
              <td>{{ plotItem.PRO_BUC }}</td>
            </tr>
            <tr>
              <td>geschützte Objekte</td>
              <td>{{ plotItem.PRO_OOB }}</td>
            </tr>
            <tr>
              <td>geschützte botanische Objekte</td>
              <td>{{ plotItem.PRO_BOO }}</td>
            </tr>
            <tr>
              <td>archäologisches Schutzgebiet</td>
              <td>{{ plotItem.PRO_ARS }}</td>
            </tr>
            <tr>
              <td>archäologische Fundstelle</td>
              <td>{{ plotItem.PRO_ARP }}</td>
            </tr>
              
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator"

@Component({
  components: {},
})
export default class ViewPlotDetails300 extends Vue {
  @Prop()
  plotItem
}
</script>

