<template>
  <div>
    <h3 class="primary pa-1 mt-4 white--text">NATUR UND UMWELT</h3>
    <div>
      <!--
      ENV-WAT	-	GEWÄSSERNETZ (FLIESSGEWÄSSER / SEEN)			
ENV-GWA	-	GRUNDWASSERVORKOMMEN			
ENV-FOR	-	WALDFESTLEGUNG / WALDFLÄCHEN- UND GRENZEN			
ENV-RES	-	NATURSCHUTZGEBIETE			
ENV-WAP	-	GEWÄSSERSCHUTZ			
EVN-ELE		ÖFFENTLICHE INFRASTRUKTUR / HOCHSPANNUNG			
EVN-TRA		ÖFFENTLICHE INFRASTRUKTUR / BAHNNETZ			
EVN-AIR	-	ÖFFENTLICHE INFRASTRUKTUR / FLUGHAFEN			
			
-->
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Name</th>
              <th class="text-left">Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Gewässernähe (500 m)</td>
              <td>{{ plotItem.ENV_WAT }}</td>
            </tr>
            <tr>
              <td>Grundwasservorkommen</td>
              <td>{{ plotItem.ENV_GWA }}</td>
            </tr>
            <tr>
              <td>Waldflächen und Grenzen</td>
              <td>{{ plotItem.ENV_FOR }}</td>
            </tr>
            <tr>
              <td>Naturschutzgebiete</td>
              <td>{{ plotItem.ENV_RES }}</td>
            </tr>
            <tr>
              <td>Gewässerschutz</td>
              <td>{{ plotItem.ENV_WAP }}</td>
            </tr>
            <tr>
              <td>Öffentliche Infrastruktur / Hochspannung</td>
              <td>{{ plotItem.EVN_ELE }}</td>
            </tr>
            <tr>
              <td>Öffentliche Infrastruktur / Bahnnetz</td>
              <td>{{ plotItem.EVN_TRA }}</td>
            </tr>
            <tr>
              <td>Öffentliche Infrastruktur / Flughafen</td>
              <td>{{ plotItem.EVN_AIR }}</td>
            </tr>
            
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator"

@Component({
  components: {},
})
export default class ViewPlotDetails300 extends Vue {
  @Prop()
  plotItem
}
</script>

