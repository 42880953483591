<template>
  <v-navigation-drawer v-model="data.plotItemViewActive" class="offset-drawer">
    <!-- Your side navigation content goes here -->
    <v-card class="d-flex flex-column" flat v-if="plotItem">
      <v-card-text class="d-flex flex-row justify-content-between">
        <div class="d-flex flex-column">
          <div class="d-flex align-center">
            <v-card-title class="pa-0"> CH-{{ plotItem.STATE }}-{{ plotItem.LOCNAME }}-{{ plotItem.PRCNO }}</v-card-title>
          </div>

          <h3><img height="16" :src="'/static/kantons/' + String(plotItem.CANTON).toLowerCase() + '.png'" /> {{ plotItem.PRC_COU }}, {{ plotItem.STREET_ADDRESS.trim() || "No address" }}, {{ plotItem.PRC_ZIP }}</h3>
        </div>
        <v-spacer></v-spacer>
        <v-btn fab elevation="0" class="red--text align-self-end ma-2" x-small @click="clearClickView"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-text>

      <v-row>
        <v-col cols="12">
          <div class="v-image v-responsive theme--dark pa-2 mx-auto" style="height: 200px">
            <canvas class="mx-auto d-block" id="canvas" width="200" height="200"></canvas>
          </div>
          <v-btn color="red lighten-2 mx-auto d-block" text @click="fullReportPDF(plotItem)"> Full Report (PDF) </v-btn>
        </v-col>
        <v-col cols="12" class="plot-full-details">
          <ViewPlotDetails100 :plotItem="plotItem" />

          <div id="objects-zone">
            <h3 class="primary pa-1 mt-4 white--text">BASISDATEN-GEBÄUDE</h3>
            <div class="mt-3 pa-3">
              <b>Objects Found ({{ objectItems ? objectItems.length : 0 }})</b>
            </div>
            <v-tabs dense align-with-title next-icon="mdi-arrow-right-bold-box-outline" prev-icon="mdi-arrow-left-bold-box-outline" show-arrows center-active v-model="tabViewObject">
              <v-tabs-slider color="blue"></v-tabs-slider>
              <v-tab v-for="(objectItem, index) of objectItems" :key="index"> {{ objectItem.OBJID }} </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabViewObject">
              <v-tab-item v-for="(objectItem, index) of objectItems" :key="index + '-det'">
                <ViewPlotDetails200 :plotItem="plotItem" :objectItem="objectItem" />
              </v-tab-item>
            </v-tabs-items>
          </div>

          <ViewPlotDetails300 :plotItem="plotItem" />
          <ViewPlotDetails400 :plotItem="plotItem" />
          <ViewPlotDetails500 :plotItem="plotItem" />
          <ViewPlotDetails600 :plotItem="plotItem" />
          <ViewPlotDetails700 :plotItem="plotItem" />
          <ViewPlotDetails800 :plotItem="plotItem" />
          <ViewPlotDetails900 :plotItem="plotItem" />
          <ViewPlotDetails1002 :plotItem="plotItem" />
        </v-col>
      </v-row>
    </v-card>
  </v-navigation-drawer>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import ViewPlotDetails100 from "./ViewPlotDetails100.vue"
import ViewPlotDetails200 from "./ViewPlotDetails200.vue"
import ViewPlotDetails300 from "./ViewPlotDetails300.vue"
import ViewPlotDetails400 from "./ViewPlotDetails400.vue"
import ViewPlotDetails500 from "./ViewPlotDetails500.vue"
import ViewPlotDetails600 from "./ViewPlotDetails600.vue"
import ViewPlotDetails700 from "./ViewPlotDetails700.vue"
import ViewPlotDetails800 from "./ViewPlotDetails800.vue"
import ViewPlotDetails900 from "./ViewPlotDetails900.vue"
import ViewPlotDetails1002 from "./ViewPlotDetails1002.vue"

@Component({
  components: {ViewPlotDetails100, ViewPlotDetails200, ViewPlotDetails300, ViewPlotDetails400, ViewPlotDetails500, ViewPlotDetails600, ViewPlotDetails700, ViewPlotDetails800, ViewPlotDetails900, ViewPlotDetails1002},
})
export default class ViewPlotDetails extends Vue {
  @Prop()
  plotItem

  @Prop()
  objectItems

  tabViewObject = 0
  tabViewDetails = 0

  data: any = {
    items: [],
    plotItemViewActive: false,
  }

  async created() {}

  @Watch("plotItem")
  async onPlotItemChange(val) {
    console.log("plotItem for view has been updated")
    if (val) {
      this.data.plotItemViewActive = true
    } else {
      this.data.plotItemViewActive = false
    }
  }

  @Watch("objectItems")
  async onObjectItemsChange() {
    console.log("objectItems for view has been updated")
  }

  async fullReportPDF(plotItem) {
    console.log("fullReportPDF", plotItem)
    this.$emit("onFullReport", plotItem)
  }

  async clearClickView() {
    this.$emit("onClearClickView")
  }
}
</script>
<style>
.offset-drawer {
  left: 295px !important; /* Offset the drawer by 300px from the left */
  min-width: 520px; /* Set the width of the drawer */
  position: absolute;
  max-width: 100%;
  margin-top: 8px;
  border-radius: 15px;
  height: calc(100% - 20px) !important;
  z-index: 1 !important;
  box-shadow: rgba(1, 1, 1, 0.5) 1px 0px 20px -8px;
}
.plot-full-details table {
  width: 100%;
}

.plot-full-details table td:first-child {
  width: 55%;
}
.plot-full-details table thead th{
  background-color: #efefef;
  font-size:15px !important;
  color: #000;
  height: 40px;
  
}
</style>
