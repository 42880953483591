import {cacheSrv} from "./../cache/cache"
import SSOPublicApi from "../api-services/server-sso-public-api"
import store from "@/store"

class RegisterService extends SSOPublicApi {
  verifyActivationKey(key) {
    return this.post("/api/user/verify-activation-key/" + key, {})
  }
  emailVerification(payload) {
    return this.post("/api/user/email-verification", payload)
  }
  async register(payload) {
    console.log("registerService")
    let result = await this.post("/api/user/register", payload)
    if (result && result.error) {
      console.log("Error login", result.message)
    } else {
      let data = result || {}
      if (data["token"]) {
        console.log("Token saved")
        store.commit(`updateToken`, data["token"])
        store.commit(`updateProfile`, data.user)
        cacheSrv.cacheEx(this.CACHE_KEY_SSO_TOKEN, data["token"], 60 * 60 * 24 * 360)
        cacheSrv.cacheEx(this.CACHE_KEY_SSO_TOKEN, data["token"], 60 * 60 * 24 * 360, "SSO")
      } else {
        console.log("Failed to register")
      }
    }
    return result
  }
}

export const registerService = new RegisterService()
