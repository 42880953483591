<template>
  <div>
    <h3 class="primary pa-1 mt-4 white--text">SONDER-NUTZUNGSPLAN</h3>
    <div>
      <!--
      SZON-PRI	-	ÜBERBAUUNGSORDNUNGEN / ZONEN MIT PLANUNGSPFLICHT / UFERSCHUTPLAN (UeO, ZPP, USP)			
-->
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Name</th>
              <th class="text-left">Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>ZPP Bravo PRI</td>
              <td>{{ plotItem.BRAVO_PRI }}</td>
            </tr>
            <tr>
              <td>UeO: Bravo SEC</td>
              <td>{{ plotItem.BRAVO_SEC }}</td>
            </tr>
            <tr>
              <td>USP: Bravo TER</td>
              <td>{{ plotItem.BRAVO_TER }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator"

@Component({
  components: {},
})
export default class ViewPlotDetails300 extends Vue {
  @Prop()
  plotItem
}
</script>

