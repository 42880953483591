<template>
  <div>
    <div>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Name</th>
              <th class="text-left">Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Object ID</td>
              <td>{{ objectItem.OBJ_ID }}</td>
            </tr>
            <tr>
              <th><b>Fussabdruck-Fläche</b></th>
              <th><b>{{objectItem.OBJ_SUR}}</b></th>
            </tr>
            <tr>
              <td><b>Gebäudevolumen</b></td>
              <td><b>{{ objectItem.OBJ_VOL }}</b></td>
            </tr>
            <tr>
              <td>Zentrierte Koordinaten</td>
              <td>{{ objectItem.OBJ_COO }}</td>
            </tr>
            <tr>
              <td>Objekttyp</td>
              <td>{{ objectItem.OBJ_TYP }}</td>
            </tr>
            <tr>
              <td>Anzahl Stockwerke</td>
              <td>{{ objectItem.OBJ_FLO }}</td>
            </tr>
            <tr>
              <td>Anzahl Nutzungseinheiten</td>
              <td>{{ objectItem.OBJ_UNI }}</td>
            </tr>
            <tr>
              <td><b>Baujahr</b></td>
              <td><b>{{ objectItem.OBJ_JOB }}</b></td>
            </tr>
            <tr>
              <td>Bauperiode</td>
              <td>{{ objectItem.OBJ_POB }}</td>
            </tr>
            <tr>
              <td>Inventarisiert</td>
              <td>{{ objectItem.OBJ_PRO }}</td>
            </tr>
            <tr>
              <td>Gemeinde</td>
              <td>{{ objectItem.OBJ_MUN }}</td>
            </tr>
            <tr>
              <td>Postleitzahl</td>
              <td>{{ objectItem.OBJ_ZIP }}</td>
            </tr>
            <tr>
              <td>Strasse</td>
              <td>{{ objectItem.OBJ_STR }}</td>
            </tr>
            <tr>
              <td>Nummer</td>
              <td>{{ objectItem.OBJ_STN }}</td>
            </tr>
            
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator"

@Component({
  components: {},
})
export default class ViewPlotDetails200 extends Vue {
  @Prop()
  plotItem

  @Prop()
  objectItem
}
</script>

