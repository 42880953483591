<template>
  <div>
    <h3 class="primary pa-1 mt-4 white--text">{{ $t("plotviewSection100") }}</h3>
    <div>
      <!-- 
      :
Grundstück-Nr.:
Grundstückfläche:
Koordinaten-Zentrum:
Land:
Kanton / Staat:
Gemeinde:
Postleitzahl:
Strasse:
Nummer:
approx. Preis / m²
approx. Preis Total:
zuständiger Geometer:
freistehend
bebaut
bebaut, teilbar

 -->
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Name</th>
              <th class="text-left">Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Grundstück-ID</td>
              <td>{{ plotItem.PRCID }}</td>
            </tr>
            <tr>
              <td><b>Grundstück-Nr</b></td>
              <td>
                <b>{{ plotItem.PRCNO }}</b>
              </td>
            </tr>
            <tr>
              <td><b>Size</b></td>
              <td>
                <b>{{ plotItem.PRCSIZE }} m²</b>
              </td>
            </tr>
            <tr>
              <td>Koordinaten-Zentrum</td>
              <td>{{ plotItem.PRC_COO_X }} - {{ plotItem.PRC_COO_Y }}</td>
            </tr>
            <tr>
              <td>Land</td>
              <td>{{ plotItem.PRC_COU }}</td>
            </tr>
            <tr>
              <td>Kanton / Staat</td>
              <td>{{ plotItem.CANTON }}</td>
            </tr>
            <tr>
              <td>Gemeinde</td>
              <td>{{ plotItem.LOCNAME }}</td>
            </tr>
            <tr>
              <td>Postleitzahl</td>
              <td>{{ plotItem.PRC_ZIP }}</td>
            </tr>
            <tr>
              <td>Strasse</td>
              <td>{{ plotItem.PRC_STR }}</td>
            </tr>
            <tr>
              <td>Nummer</td>
              <td>{{ plotItem.PRC_STN }}</td>
              </tr>
            <tr>
              <td>approx. Preis / m²</td>
              <td>{{ plotItem.PRC_PPM }}</td>
            </tr>
            <tr>
              <td>approx. Preis Total</td>
              <td>{{ plotItem.PRC_PTO }}</td>
            </tr>
            <tr>
              <td>zuständiger Geometer</td>
              <td>{{ plotItem.PRC_GEO }}</td>
            </tr>
            <tr>
              <td>freistehend</td>
              <td>{{ plotItem.PRC_FRE }}</td>
            </tr>
            <tr>
              <td>bebaut</td>
              <td>{{ plotItem.PRC_BUI }}</td>
            </tr>
            <tr>
              <td>bebaut, teilbar</td>
              <td>{{ plotItem.PRC_RES }}</td>
            </tr>

            <tr></tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator"

@Component({
  components: {},
})
export default class ViewPlotDetails100 extends Vue {
  @Prop()
  plotItem
}
</script>
