<template>
  <v-card class="mb-3" color="grey lighten-4">
    <v-card-title class="pointer subtitle-1" @click="viewPlotMap(plotItem.PRCID, index)">
      CH-{{ plotItem.STATE }}-{{ plotItem.LOCNAME }}-{{ plotItem.PRCNO }}
      <v-spacer></v-spacer>
      <span v-if="plotItem.RADON > 10" style="color: red; font-size: 20px">&#9888;</span>
    </v-card-title>
    <div @click="viewPlotMap(plotItem.PRCID, index)" class="v-image v-responsive theme--dark pointer" style="height: 120px; border-radius: 10px">
      <canvas class="mx-auto" :id="'canvas_' + plotItem.PRCID" width="120" height="120"></canvas>
    </div>

    <v-card-text>
      <div>
        <img height="16" :src="'/static/kantons/' + String(plotItem.CANTON).toLowerCase() + '.png'" /> {{ plotItem.CANTON }} -
        {{ plotItem.LOCNAME }}
      </div>
      <div>{{ plotItem.STREET_ADDRESS ? plotItem.STREET_ADDRESS.trim() : "No address" }}</div>

      <div class="my-2">{{ plotItem.PRCPRICEM2 }} <span class="grey--text">p/m2</span> • {{ plotItem.PRCSIZE }} <span class="grey--text">size</span> {{ plotItem.FLOORSIB ? " • " + plotItem.FLOORSIB : "" }} <span v-if="plotItem.FLOORSIB" class="grey--text">floors</span></div>

      <div>{{ plotItem.ZON_TYP1 }} <span class="grey--text">zone 1</span> • {{ plotItem.ZON_TYP2 }} <span class="grey--text">zone 2</span></div>
    </v-card-text>

    <v-card-actions v-if="false">
      <v-btn color="deep-purple lighten-2" text @click="fullReportPDF(plotItem)"> Full Report (PDF) </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from "vue-property-decorator"

@Component({
  components: {},
})
export default class PlotItemSelect extends Vue {
  @Prop()
  plotItem

  @Prop()
  index

  tab: any = []

  data: any = {
    items: [],
  }

  async created() {}

  async viewPlotMap(PRCID, index) {
    console.log("viewPlotMap", PRCID, index)
    this.$emit("onClick", PRCID, index)
  }

  async fullReportPDF(plotItem) {
    console.log("fullReportPDF", plotItem)
    this.$emit("onFullReport", plotItem)
  }
}
</script>
