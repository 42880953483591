// src/i18n.js
import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
  en: {
    language: 'English',
    plotviewSection100: 'BASIC DATA - PARCEL',
    plotviewSection200: 'BASIC DATA - BUILDING',
    search: 'Search',
    // Add more translations here
  },
  de: {
    language: 'DEUTSCH',
    plotviewSection100: 'BASISDATEN - PARZELLE',
    plotviewSection200: 'BASISDATEN-GEBÄUDE',
    search: 'Suche',
    // Add more translations here
  },
  // Add more languages here
  //switzerland
  fr: {
    language: 'FRANÇAIS',
    plotviewSection100: 'DONNÉES DE BASE - PARCELLE',
    plotviewSection200: 'DONNÉES DE BASE - BÂTIMENT',
    search: 'Rechercher',
    // Add more translations here
  },
  it: {
    language: 'ITALIANO',
    plotviewSection100: 'DATI DI BASE - PARCELLA',
    plotviewSection200: 'DATI DI BASE - EDIFICIO',
    search: 'Ricerca',
    // Add more translations here
  },

};

const i18n = new VueI18n({
  locale: 'en', // Set the default locale
  messages,
});

export default i18n;