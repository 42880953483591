<template>
  <div>
    <h3 class="primary pa-1 mt-4 white--text">BASIS-NUTZUNGSPLAN</h3>
    <div>
      <!--
      BZON-PRI	-	NATIONAL > Alpha PRI		(ALPHA; PRI)		
BZON-SEC	-	KANTONAL > Alpha SEC		(ALPHA; SEC)		
BZON-TER	-	KOMMUNAL > Alpha TER		(ALPHA; TER)		
-->
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Name</th>
              <th class="text-left">Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Alpha PRI</td>
              <td>{{ plotItem.ALPHA_PRI }}</td>
            </tr>
            <tr>
              <td>Alpha SEC</td>
              <td>{{ plotItem.ALPHA_SEC }}</td>
            </tr>
            <tr>
              <td>Alpha TER</td>
              <td>{{ plotItem.ALPHA_TER }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator"

@Component({
  components: {},
})
export default class ViewPlotDetails300 extends Vue {
  @Prop()
  plotItem
}
</script>

