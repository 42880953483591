<template>
  <div>
    <h3 class="primary pa-1 mt-4 white--text">NUTZUNGSPLAN-ÜBERLAGERUNGEN</h3>
    <div>
      <!--
      OVL-COL	-	BASIS-BAULINIEN
OVL-CSL	-	BAULINIEN-KANTONSSTRASSEN
OVL-NSL	-	BAULINIEN NATIONALSTRASSEN
OVL-FOL	-	BAULINIEN WALDABSTAND
OVL-NOI	-	LÄRMEMPFINDLICHKEITSSTUFE
OVL-WAL	-	GEWÄSSERRAUMLINIE
OVL-WAA	-	GEWÄSSERRAUM
			
-->
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Name</th>
              <th class="text-left">Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Basis-Baulinien</td>
              <td>{{ plotItem.OVL_COL }}</td>
            </tr>
            <tr>
              <td>Baulinien-Kantonsstrassen</td>
              <td>{{ plotItem.OVL_CSL }}</td>
            </tr>
            <tr>
              <td>Baulinien Nationalstrassen</td>
              <td>{{ plotItem.OVL_NSL }}</td>
            </tr>
            <tr>
              <td>Baulinien Waldabstand</td>
              <td>{{ plotItem.OVL_FOL }}</td>
            </tr>
            <tr>
              <td>Lärmempfindlichkeitsstufe</td>
              <td>{{ plotItem.OVL_NOI }}</td>
            </tr>
            <tr>
              <td>Gewässerraumlinie</td>
              <td>{{ plotItem.OVL_WAL }}</td>
            </tr>
            <tr>
              <td>Gewässerraum</td>
              <td>{{ plotItem.OVL_WAA }}</td>
            </tr>
            
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator"

@Component({
  components: {},
})
export default class ViewPlotDetails300 extends Vue {
  @Prop()
  plotItem
}
</script>

