<template>
  <div>
    <h3 class="primary pa-1 mt-4 white--text">NATURGEFAHREN</h3>
    <div>
      <!--
      HAZ-FAL	-	STURZGEFAHREN	
HAZ-LAV	-	LAWINENGEFAHREN	
HAZ-SLI	-	RUTSCHGEFAHREN	
HAZ-WAT	-	WASSERGEFAHREN	
HAZ-DOL	-	DOLINENGEFAHREN	
		
-->
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Name</th>
              <th class="text-left">Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Sturzgefahr</td>
              <td>{{ plotItem.HAZ_FAL }}</td>
            </tr>
            <tr>
              <td>Lawinengefahr</td>
              <td>{{ plotItem.HAZ_LAV }}</td>
            </tr>
            <tr>
              <td>Rutschgefahr</td>
              <td><b>{{ plotItem.HAZ_SLI }}</b></td>
            </tr>
            <tr>
              <td>Wassergefahr</td>
              <td><b>{{ plotItem.HAZ_WAT }}</b></td>
            </tr>
            <tr>
              <td>Dolinengefahr</td>
              <td>{{ plotItem.HAZ_DOL }}</td>
            </tr>

          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator"

@Component({
  components: {},
})
export default class ViewPlotDetails300 extends Vue {
  @Prop()
  plotItem
}
</script>

