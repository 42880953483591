import Component from "vue-class-component"
Component.registerHooks(["beforeRouteEnter", "beforeRouteLeave", "beforeRouteUpdate"])
import Vue from "vue"
import App from "./App.vue"
/* eslint-disable */
import router from "./router"
import store from "./store"
import vuetify from "./plugins/vuetify"
import AppDotty from "./layouts/AppDotty.vue"
import SimpleLayout from "./layouts/SimpleLayout.vue"
import MapsLayout from "./layouts/MapsLayout.vue"
import VueApexCharts from "vue-apexcharts"
import i18n from './i18n'; // Import the i18n configuration

Vue.use(VueApexCharts)
Vue.component("apexchart", VueApexCharts)
import {mainInit} from "./libs/app-init/main-init"
import {appFilters} from "./libs/app-init/app-filters"
// import "./registerServiceWorker"
import {userService} from "./libs/user/user-service"

Vue.config.productionTip = false

appFilters.initialise(Vue)

async function initializeApp() {
  await mainInit.initialise()
  await userService.initUser()
}
initializeApp().then((_) => {
  new Vue({
    router,
    store,
    vuetify,
    i18n, 
    render: (h) => h(App),
  }).$mount("#app")
})
Vue.component("app-layout", AppDotty)
Vue.component("simple-layout", SimpleLayout)
Vue.component("maps-layout", MapsLayout)
