import axios from "axios"
import {cacheSrv} from "../cache/cache"
import eventBus from "./../../eventBus"

export default class BaseApi {
  CACHE_KEY_SSO_TOKEN = "ssotok"
  apiUrl = ""
  ssoToken: any

  async precheck(method: string, url: string, payload: any, headers: any) {
    return true
  }

  async delete(url: string, payload: any, headers: any = {}) {
    let result = await this.precheck("delete", url, payload, headers)
    if (result) {
      try {
        let result = await axios.delete(this.getUrl(url), Object.assign({}, {params: payload}, this.loadHeaders(headers)))
        return result.data
      } catch (error) {
        if (error instanceof Error) {
          console.error(error)
          if (error && error["message"] && error["message"].indexOf("403") >= 0) {
            eventBus.$emit("display-snackbar", {type: "error", text: "Access denied. Please login with correct credentials to access the system"})
          }
        }
      }
    }
    return null
  }
  async post(url: string, payload: any, headers: any = {}) {
    let checked = await this.precheck("post", url, payload, headers)
    if (checked) {
      let result: any = null
      try {
        result = await axios.post(this.getUrl(url), payload, this.loadHeaders(headers))
        return result.data
      } catch (error: any) {
        if (axios.isAxiosError(error)) {
          console.error("error", error)
          if (error && error["message"] && error["message"].indexOf("403") >= 0) {
            eventBus.$emit("display-snackbar", {type: "error", text: "Access denied. Please login with correct credentials to access the system"})
          } else if (error && error["message"] && error["message"]) {
            eventBus.$emit("display-snackbar", {type: "error", text: error["message"]})
          }
        }
        if (error.response) {
          return error.response ? error.response.data : null
        }
        return null
      }
    }
    return null
  }

  async get(url: string, payload: any = {}, pagination: any = {}, headers: any = {}) {
    let result = await this.precheck("get", url, payload, headers)
    if (result) {
      try {
        let newpay = Object.assign({}, payload, pagination)
        let options = Object.assign({}, {params: newpay}, this.loadHeaders(headers))
        let result = await axios.get(this.getUrl(url), options)
        return result.data
      } catch (error) {
        if (error instanceof Error) {
          console.error(error)
          if (error && error["message"] && error["message"].indexOf("403") >= 0) {
            eventBus.$emit("display-snackbar", {type: "error", text: "Access denied. Please login with correct credentials to access the system"})
          }
        }
      }
    }
    return null
  }

  async put(url: string, payload: any, headers: any = {}) {
    let result = await this.precheck("put", url, payload, headers)
    if (result) {
      try {
        let result = await axios.put(this.getUrl(url), payload, this.loadHeaders())
        return result.data
      } catch (error) {
        if (error instanceof Error) {
          console.error(error)
          if (error && error["message"] && error["message"].indexOf("403") >= 0) {
            eventBus.$emit("display-snackbar", {type: "error", text: "Access denied. Please login with correct credentials to access the system"})
          }
        }
      }
    }
    return null
  }

  async postUpload(url: string, payload: any, options: any, headers: any = {}) {
    let result = await this.precheck("postUpload", url, payload, headers)
    if (result) {
      try {
        let optionsAppended = Object.assign({}, this.loadHeaders(headers), options)
        optionsAppended["headers"]["Content-Type"] = "multipart/form-data"
        console.log("optionsAppended", optionsAppended)
        let result = await axios.post(this.getUrl(url), payload, optionsAppended)
        return result.data
      } catch (error) {
        if (error instanceof Error) {
          console.error(error)
          if (error && error["message"] && error["message"].indexOf("403") >= 0) {
            eventBus.$emit("display-snackbar", {type: "error", text: "Access denied. Please login with correct credentials to access the system"})
          }
        }
      }
    }
    return null
  }

  async authorize(url: string, ssoToken: string) {
    try {
      let result = await axios.post(url, {}, {headers: {authorization: "Bearer " + ssoToken}})
      return result.data
    } catch (error) {
      if (error instanceof Error) {
        console.error(error)
        if (error && error["message"] && error["message"].indexOf("403") >= 0) {
          eventBus.$emit("display-snackbar", {type: "error", text: "Access denied. Please login with correct credentials to access the system"})
        }
      }
      return null
    }
  }

  getSSOToken() {
    if (!this.ssoToken) {
      this.ssoToken = cacheSrv.cached(this.CACHE_KEY_SSO_TOKEN)
    }
    if (!this.ssoToken) {
      return null
    }
    return this.ssoToken
  }
  getUrl(url = "") {
    let urlWithPath = this.apiUrl + url
    return urlWithPath
  }
  loadHeaders(headers: any = {}) {
    if (Object.keys(headers).length == 0) {
      return {}
    }
    return {headers: headers}
  }
}
