<template>
  <v-app id="simple" >
    <v-app-bar dark app clipped-right color="primary" >
      <v-container fluid class="py-0 fill-height">
        <div class="d-none d-md-block">
          <v-btn to="/" text> {{$t("search")}} </v-btn>
        </div>
        <v-spacer></v-spacer>
        <v-btn to="/register" text>Join </v-btn>
        <v-btn to="/login" text v-if="!user">Login </v-btn>
        <span v-if="user">{{ user.name || user.username }} </span>
      </v-container>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
      <v-snackbar v-model="data.snackbar.visible" :color="data.snackbar.color">
        {{ data.snackbar.text }}
        <template v-slot:action="{attrs}">
          <v-btn :color="data.snackbar.buttonColor" text v-bind="attrs" @click="data.snackbar.visible = false">Close</v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator"
import EventBus from "./../eventBus"

@Component({})
export default class SimpleLayout extends Vue {
  links: any = ["Search", "Profile", "Logout"]

  user: any = null

  data: any = {
    drawer: null,
    snackbar: {
      visible: false,
      text: "",
      buttonColor: "white",
    },
  }

  constructor() {
    super()
  }

  created() {
    EventBus.$on("display-snackbar", this.displaySnackbar)
    EventBus.$on("user-session-event", this.userSessionEvent)
  }

  beforeDestroy() {
    EventBus.$off("display-snackbar", this.displaySnackbar)
    EventBus.$off("user-session-event", this.userSessionEvent)
  }
  userSessionEvent(payload: any) {
    console.log("userSessionEvent: ", payload)
    Vue.set(this, "user", payload.user)
  }

  displaySnackbar(payload: any) {
    this.data.snackbar.visible = false // first close any open snackbar
    this.data.snackbar.text = payload.text
    this.data.snackbar.visible = true

    switch (payload.type) {
      case "success": {
        this.data.snackbar.color = "success"
        this.data.snackbar.buttonColor = "white"
        break
      }
      case "error": {
        this.data.snackbar.color = "error"
        this.data.snackbar.buttonColor = "white"
        break
      }
      case "warning": {
        this.data.snackbar.color = "warning"
        //this.data.snackbar.buttonColor = "white";
        break
      }
      case "info": {
        this.data.snackbar.color = "info"
        //this.data.snackbar.buttonColor = "white";
        break
      }
      default: {
        this.data.snackbar.color = "primary"
        this.data.snackbar.buttonColor = "white"
        break
      }
    }
  }
}
</script>
