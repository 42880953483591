<template>
  <div>
    <h3 class="primary pa-1 mt-4 mt-4 white--text">FIXPUNKTENETZ (HFP+LFP 1-3)</h3>
    <div>
      <!--
      FIX-IDE	-	FIXPUNKTE-ID
FIX-NUM	-	FIXPUNKTE-NUMMER
FIX-NAM	-	FIXPUNKTE-NAME
FIX-TYP	-	FIXPUNKTE-TYP
FIX-COO	-	KOORDINATEN
FIX-ASL	-	MEERESHÖHE 
FIX-DTP	-	DISTANZ ZUR PARZELLE
	
-->
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Name</th>
              <th class="text-left">Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Fixpunkt-ID</td>
              <td>{{ plotItem.FIX_IDE }}</td>
            </tr>
            <tr>
              <td>Fixpunkt-Nummer</td>
              <td>{{ plotItem.FIX_NUM }}</td>
            </tr>
            <tr>
              <td>Fixpunkt-Name</td>
              <td>{{ plotItem.FIX_NAM }}</td>
            </tr>
            <tr>
              <td>Fixpunkt-Typ</td>
              <td>{{ plotItem.FIX_TYP }}</td>
            </tr>
            <tr>
              <td>Koordinaten</td>
              <td>{{ plotItem.FIX_COO }}</td>
            </tr>
            <tr>
              <td>Meereshöhe</td>
              <td>{{ plotItem.FIX_ASL }}</td>
            </tr>
            <tr>
              <td>Distanz zur Parzelle</td>
              <td>{{ plotItem.FIX_DTP }}</td>
            </tr>
            
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator"

@Component({
  components: {},
})
export default class ViewPlotDetails300 extends Vue {
  @Prop()
  plotItem
}
</script>

